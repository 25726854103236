import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

const Account = () => import('../views/Account')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Clock',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Clock.vue'),
    meta: {
      open: true,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      open: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.open)) {
    try {
      if (store.state.user == null) {
        next({
          path: '/account',
          params: { nextUrl: to.fullPath },
        })
      } else {
        next()
      }
    } catch (error) {
      next({
        path: '/account',
        params: { nextUrl: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
