<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  data: () => ({
    time: null,
  }),
  async mounted() {
    this.$store.dispatch('getUsers')
    setInterval(() => {
      const now = new Date(Date.now())
      this.time = now.toLocaleTimeString()
    }, 1000)
    //todo notify user if server is down
  },
  methods: {},
}
</script>
