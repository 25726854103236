import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '../router/index'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    users: [],
    user: null,
    snackbarShow: false,
    snackbarMessage: '',
  },
  mutations: {
    setSnackbarMessage(state, payload) {
      state.snackbarMessage = payload
    },
    setSnackbarShow(state, payload) {
      state.snackbarShow = payload
    },
    setUser(state, payload) {
      state.user = payload
      state.authenticated = false
    },

    logout(state) {
      state.user = null
      state.jwt = null
      router.push('account')
    },
    setUsers(state, payload) {
      state.users = payload
    },
  },
  actions: {
    getUsers({ commit }) {
      return this._vm.axios
        .get('employees')
        .then((res) => {
          commit('setUsers', res.data)
        })
        .catch((err) => {
          return err
        })
    },
    login({ commit }, payload) {
      Vue.axios
        .post('auth/local', {
          identifier: payload.identifier,
          password: payload.password,
        })
        .then((res) => {
          commit('setUser', res.data)
          router.push('/admin')
        })
        .catch((err) => {
          commit('setSnackbarMessage', err.response.data.message)
          commit('setSnackbarShow', true)
          console.error(err)
        })
    },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => {
          let expiry = new Date(Date.now())
          expiry.setTime(expiry.getTime() + 4 * 60 * 60 * 1000)
          return Cookies.set(key, value, {
            expires: expiry,
            secure: true,
            sameSite: 'Strict',
          })
        },
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  modules: {},
})
